import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import CoreuiVue from '@coreui/vue';
import '@coreui/coreui/dist/css/coreui.min.css';
import LoadingPlugin from './plugins/Loading/LoadingPlugin';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFloppyDisk } from '@fortawesome/pro-regular-svg-icons';
import Datepicker from '@vuepic/vue-datepicker';
import '@/plugins/Loading/LoadingStyle.css';
import '@vuepic/vue-datepicker/dist/main.css';
import Maska from 'maska';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import { createI18n } from 'vue-i18n';
import ConfirmationService from 'primevue/confirmationservice';
import Multiselect from 'vue-multiselect';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import 'vue-multiselect/dist/vue-multiselect.css';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice';
import vueVimeoPlayer from 'vue-vimeo-player';
// import 'primeflex/primeflex.css';

const i18n = createI18n({
  locale: localStorage.getItem('lang') || 'fr', // set default locale
});

library.add(fas, fal, far, faFloppyDisk);
createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('Datepicker', Datepicker)
  .component('multiselect', Multiselect)
  .component('Dropdown', Dropdown)
  .component('Button', Button)
  .component('InputText', InputText)
  .component('Textarea', Textarea)
  .component('Toast', Toast)
  .use(store)
  .use(router)
  .use(CoreuiVue)
  .use(Maska)
  .use(PrimeVue)
  .use(ToastService)
  .use(ConfirmationService)
  .use(DialogService)
  .use(LoadingPlugin)
  .use(vueVimeoPlayer)
  .use(i18n)
  .mount('#app');
