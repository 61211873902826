
import AccountType from '@/types/enums/AccountType';
import { defineComponent } from 'vue';
import commonMessages from '../locales/common';
export default defineComponent({
  name: 'DashBoard',
  i18n: {
    sharedMessages: commonMessages,
  },
  data() {
    return {
      userName: '',
    };
  },
  computed: {
    listeDeLiens() {
      let listeDeLiensToReturn;
      const lienAdmin = [
        {
          num: 1,
          type: 'title',
          text: 'DISTRIBUTION COMMUNAUTAIRE',
          items: [
            {
              num: 3,
              to: { name: 'ListeCouponsAdmin' },
              img: {
                src: require('../assets/img/icone-coupons-manuels.svg'),
                alt: 'icone coupons manuels',
              },
              text: 'Coupons créés manuellement',
              id: 'btn-coupons',
            },
            {
              num: 4,
              to: { path: '/community-distribution-report' },
              img: {
                src: require('../assets/img/icone-commandes.svg'),
                alt: 'icone commandes',
              },
              text: 'Commandes',
            },
            {
              num: 5,
              to: { path: '/distribution-site-report' },
              img: {
                src: require('../assets/img/icone-rapport-sd.svg'),
                alt: 'icone rapport sd',
              },
              text: 'Rapport - Sites de distribution',
            },
            {
              num: 6,
              to: { name: 'RapportEcAdmin' },
              img: {
                src: require('../assets/img/icone-rapport-ec.svg'),
                alt: 'icone rapport état commandes',
              },
              text: 'Rapport - État des commandes',
            },
            {
              num: 14,
              to: { name: 'RapportCs' },
              img: {
                src: require('../assets/img/icone-rapport-ec.svg'),
                alt: 'icone rapport commandes supprimées',
              },
              text: 'Rapport - Commandes supprimées',
            },
          ],
        },
        {
          num: 7,
          type: 'title',
          text: 'CHASSE ET PÊCHE',
          items: [
            {
              num: 8,
              to: { name: 'RapportCommandesPermisAdmin' },
              img: {
                src: require('../assets/img/icone-rapport-cp.svg'),
                alt: 'icone rapport commandes permis',
              },
              text: 'Rapport - Commandes de permis',
            },
          ],
        },
        {
          num: 9,
          type: 'title',
          text: "PARAMÈTRES DE L'APPLICATION",
          items: [
            {
              num: 10,
              to: { name: 'ListeUtilisateurs' },
              img: {
                src: require('../assets/img/icone-liste-utilisateurs.svg'),
                alt: 'icone liste utilisateurs',
              },
              text: 'Liste des utilisateurs',
              tag: 'listeUtilisateurs',
            },
            {
              to: { name: 'assembleeGenerale' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone assemblee generale',
              },
              text: this.$t('visionnerAssembleeGenerale'),
            },
            {
              to: { name: 'elections' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone profil',
              },
              text: this.$t('elections'),
            },
            {
              to: { name: 'reponsesCandidatsElections' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone profil',
              },
              text: this.$t('reponsesCandidatsElections'),
            },
            {
              to: { name: 'espaceVideo' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone profil',
              },
              text: this.$t('espaceVideo'),
            },
            {
              to: { name: 'importationCouponsPromo' },
              img: {
                src: require('../assets/img/icone-commandes.svg'),
                alt: 'icone importation coupons',
              },
              text: this.$t('importationCouponsPromo'),
            },
          ],
        },
        {
          num: 11,
          type: 'title',
          text: "INSCRIPTION À L'ASSEMBLÉE GÉNÉRALE",
          items: [
            {
              num: 12,
              to: { name: 'ListeInscriptionsAssemblee' },
              img: {
                src: require('../assets/img/icone-inscriptions.svg'),
                alt: 'liste inscriptions assemblee',
              },
              text: 'Liste des inscriptions',
            },
            {
              num: 13,
              to: { name: 'ListeInscriptionsAssembleeAdmin' },
              img: {
                src: require('../assets/img/icone-inscriptions-manuelles.svg'),
                alt: 'liste inscriptions assemblee admin',
              },
              text: 'Liste des inscriptions manuelles',
            },
          ],
        },
      ];
      const lienDistributor = [
        {
          num: 1,
          type: 'title',
          text: '',
          items: [
            {
              to: { name: 'OrderScan' },
              img: {
                src: require('../assets/img/icon-admin.png'),
                alt: 'icone scanner commandes',
              },
              text: 'Scanner une commande',
            },
            {
              to: { name: 'RapportEc' },
              img: {
                src: require('../assets/img/icon-admin.png'),
                alt: 'icone rapport état commandes',
              },
              text: 'Rapport état des commandes',
            },
          ],
        },
      ];
      const lienUser = [
        {
          num: 1,
          type: 'title',
          text: '',
          items: [
            {
              to: { name: 'assembleeGenerale' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone assemblee generale',
              },
              text: this.$t('visionnerAssembleeGenerale'),
            },
            {
              to: { name: 'couponPromoMembre' },
              img: {
                src: require('../assets/img/icone-coupons.svg'),
                alt: 'icone coupons',
              },
              text: this.$t('monCouponPromo'),
            },
            {
              to: { name: 'reponsesCandidatsElections' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone profil',
              },
              text: this.$t('reponsesCandidatsElections'),
            },
            {
              to: { name: 'elections' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone profil',
              },
              text: this.$t('elections'),
            },
            {
              to: { name: 'espaceVideo' },
              img: {
                src: require('../assets/img/icon-espace-video.svg'),
                alt: 'icone profil',
              },
              text: this.$t('espaceVideo'),
            },
            {
              to: { name: 'coupons' },
              img: {
                src: require('../assets/img/icone-coupons.svg'),
                alt: 'icone coupons',
              },
              text: this.$t('mesCoupons'),
              id: 'btn-coupons',
            },
            {
              to: { name: 'permis' },
              img: {
                src: require('../assets/img/icone-permis.svg'),
                alt: 'icone permis',
              },
              text: this.$t('mesPermis'),
              id: 'btn-permis',
            },
            {
              to: { name: 'profil' },
              img: {
                src: require('../assets/img/icone-profil.png'),
                alt: 'icone profil',
              },
              text: this.$t('updateProfile'),
            },
          ],
        },
      ];
      const accountType = this.$store.getters['Auth/accountType'];
      switch (accountType) {
        case AccountType.User:
          listeDeLiensToReturn = lienUser;
          break;
        case AccountType.Admin:
          listeDeLiensToReturn = lienAdmin;
          break;
        case AccountType.Distributor:
          listeDeLiensToReturn = lienDistributor;
          break;
      }
      return listeDeLiensToReturn;
    },
  },
  created() {
    this.userName = JSON.parse(sessionStorage.user).firstName;
  },
});
