import { FilderDateDTO } from '@/types/store/DTO/FilderDateDTO';
import { InscriptionAssembleeDTO } from '@/types/store/DTO/InscriptionAssembleeDTO';
import axios from 'axios';
import authHeader from './AuthHeaderAdmin';
const API_URL = process.env.VUE_APP_BACKEND_URL;
class AdminService {
  getRapports() {
    return axios.get(API_URL + 'commandes', { headers: authHeader() });
  }
  getCommandesMadeByAdminsPerPage(currentPage: number) {
    return axios.post(
      API_URL + 'getCommandesMadeByAdminsPerPage',
      { currentPage },
      {
        headers: authHeader(),
      }
    );
  }
  getcommandesWithDate(data: FilderDateDTO) {
    return axios.post(API_URL + 'commandesWithDate', data, {
      headers: authHeader(),
    });
  }
  getInscriptionsWithDates(data: FilderDateDTO) {
    return axios.post(API_URL + 'inscriptionsWithDates', data, {
      headers: authHeader(),
    });
  }
  getInscriptionsAdminWithDates(data: FilderDateDTO) {
    return axios.post(API_URL + 'inscriptionsAdminWithDates', data, {
      headers: authHeader(),
    });
  }
  getAllPermis() {
    return axios.get(API_URL + 'getAllPermis', {
      headers: authHeader(),
    });
  }
  getCommandesPermisCsv() {
    return axios.get(API_URL + 'commandesPermisCsv', {
      headers: authHeader(),
      responseType: 'arraybuffer',
    });
  }
  getCommandesCsv() {
    return axios.get(API_URL + 'commandesCsv', {
      headers: authHeader(),
      responseType: 'arraybuffer',
    });
  }
  getCommandesCsvWithDates(data: FilderDateDTO) {
    return axios.post(API_URL + 'commandesCsvWithDates', data, {
      headers: authHeader(),
      responseType: 'arraybuffer',
    });
  }
  getCommandesAdminCsv() {
    return axios.get(API_URL + 'commandesAdminCsv', {
      headers: authHeader(),
      responseType: 'arraybuffer',
    });
  }
  getCommandesPdf() {
    return axios.get(API_URL + 'commandesPDF', {
      headers: authHeader(),
      responseType: 'arraybuffer',
    });
  }
  getCommandesPermisPdf(commandePermisIDS) {
    return axios.post(API_URL + 'commandesPermisPdf', { commandePermisIDS: commandePermisIDS }, {
      headers: authHeader(),
      responseType: 'arraybuffer',
    });
  }
  getRapportBySite(site?: { site: string }) {
    const uri = 'getRapportBySiteSelf';
    const body = undefined;
    return axios.post(API_URL + uri, body, {
      headers: authHeader(),
    });
  }
  getPdfSite(site: string) {
    return axios.post(
      API_URL + 'pdfSite',
      { site: site },
      {
        headers: authHeader(),
        responseType: 'arraybuffer',
      }
    );
  }
  getCommandesSiteCsv(site: string) {
    return axios.post(
      API_URL + 'commandeSiteCsv',
      { site: site },
      {
        headers: authHeader(),
        responseType: 'arraybuffer',
      }
    );
  }
  getCouponGibier(id: string) {
    return axios.get(API_URL + 'getCouponGibier/' + id, {
      headers: authHeader(),
    });
  }
  getCouponPoisson(id: string) {
    return axios.get(API_URL + 'getCouponPoisson/' + id, {
      headers: authHeader(),
    });
  }
  getAllInscriptionsAssemblee() {
    return axios.get(API_URL + 'getAllInscriptionsAssemblee', {
      headers: authHeader(),
    });
  }
  getAllInscriptionsAssembleeByAdmin() {
    return axios.get(API_URL + 'getAllInscriptionsAssembleeByAdmin', {
      headers: authHeader(),
    });
  }
  getAllInscriptions() {
    return axios.get(API_URL + 'getAllInscriptions', {
      headers: authHeader(),
    });
  }
  getAllCommandesPermis() {
    return axios.get(API_URL + 'getAllCommandesPermis', {
      headers: authHeader(),
    });
  }
  getInscriptionAdminById(id: string) {
    return axios.get(API_URL + 'getInscriptionAdminById/' + id, {
      headers: authHeader(),
    });
  }
  createInscriptionAdmin(data: InscriptionAssembleeDTO) {
    return axios.post(API_URL + 'createInscriptionAdmin', data, {
      headers: authHeader(),
    });
  }
  updateInscriptionAdmin(data: InscriptionAssembleeDTO) {
    return axios.post(API_URL + 'updateInscriptionAdmin', data, {
      headers: authHeader(),
    });
  }
  deleteInscriptionAdmin(id: string) {
    return axios.delete(API_URL + 'deleteInscriptionAdmin', {
      data: { id },
      headers: authHeader(),
    });
  }
  getEtatCommandesCsv(payload: object) {
    return axios.post(API_URL + 'etatCommandeCsv', payload, {
      headers: authHeader(),
      responseType: 'arraybuffer',
    });
  }
  getAllDeletedCommandes() {
    return axios.get(API_URL + 'commandes-supprimees', {
      headers: authHeader(),
    });
  }
  getCommandesSupprimeesWithDates(data: FilderDateDTO) {
    return axios.post(API_URL + 'commandes-supprimees-with-dates', data, {
      headers: authHeader(),
    });
  }
  getCommandesSupprimeesCsv() {
    return axios.get(API_URL + 'commandes-supprimees-csv', {
      headers: authHeader(),
      responseType: 'arraybuffer',
    });
  }
  getCommandesSupprimeesCsvWithDates(data: FilderDateDTO) {
    return axios.post(API_URL + 'commandes-supprimees-csv-with-dates', data, {
      headers: authHeader(),
      responseType: 'arraybuffer',
    });
  }
  importFileCouponsPromo(file) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(API_URL + 'importFile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...authHeader(),
      },
    });
  }
}
export default new AdminService();
