
import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { CommandePermisDTO } from '@/types/store/DTO/CommandePermisDTO';
import { dateParserLong } from '@/helpers/dateParserHelper';
import Card from '@/components/Card.vue';
import { UsersDTO } from '@/types/store/DTO/UsersDTO';
export default defineComponent({
  name: 'RapportCp',
  components: { DataTable, Column, Card },
  data() {
    return {
      rapports: new Array<CommandePermisDTO>(),
      copieRapports: new Array<CommandePermisDTO>(),
      ico: 'far',
      site: -1,
      search: '',
      isAdmin: false,
      selectedOrder: {} as CommandePermisDTO,
    };
  },
  methods: {
    dateParserLong,
    padLeadingZeros(num: number) {
      let s = num + '';
      while (s.length < 7) s = '0' + s;
      return s;
    },
    getCommandesPermis() {
      this.$store
        .dispatch('Permis/getAllCommandesPermis')
        .then((data: Array<CommandePermisDTO>) => {
          this.rapports = data;
          this.copieRapports = this.rapports;
        });
    },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      const search = this.search;
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(search.toLowerCase())
        )
      );
    },
    getIfCommandeMatch(commande: CommandePermisDTO) {
      return (
        this.getIfStringMatchSearch(this.padLeadingZeros(commande.id)) ||
        this.getIfStringMatchSearch(commande.firstName) ||
        this.getIfStringMatchSearch(commande.lastName) ||
        this.getIfStringMatchSearch(commande.email) ||
        this.getIfStringMatchSearch(dateParserLong(commande.created_at)) ||
        this.getIfStringMatchSearch(commande.memberId) ||
        this.getIfStringMatchSearch(commande.adress) ||
        this.getIfStringMatchSearch(commande.city) ||
        this.getIfStringMatchSearch(commande.postalCode) ||
        this.getIfStringMatchSearch(commande.noCertificat) ||
        this.getIfStringMatchSearch(commande.squareReceiptNumber) ||
        this.getIfStringMatchSearch(dateParserLong(commande.birthDate)) ||
        this.getIfStringMatchSearch(commande.bandNumber)
      );
    },
    filter() {
      this.rapports = this.copieRapports;
      const result = this.rapports.filter(this.getIfCommandeMatch);
      this.rapports = result;
    },
    exportCSV() {
      this.$store.dispatch('Rapports/getCommandesPermisCsv').then();
    },
    exportPdf() {
      //make an array of commandesPermis id with this.rapports
      const commandesPermisIds = this.rapports.map(
        (commandePermis) => commandePermis.id
      );
      this.$store.dispatch('Rapports/getCommandesPermisPdf', commandesPermisIds).then();
    },
  },
  created() {
    this.isAdmin = JSON.parse(sessionStorage.user).role == 'admin';
    this.getCommandesPermis();
  },
});
