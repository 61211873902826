<script setup>
import { ref, onMounted } from 'vue';

const videoUrl = 'https://vimeo.com/1012405161/873d9dc62e';
// Make a width value that takes 100% in px
const playerWidth = ref(window.innerWidth); // 32px is the padding
const playerHeight = ref((window.innerWidth * 9) / 16); // for 16:9 aspect ratio
</script>
<template>
  <div class="video-player-container">
    <vimeo-player
      :video-url="videoUrl"
      :playerWidth="playerWidth"
      :playerHeight="playerHeight"
    />
  </div>
</template>
<style scoped>
.video-player-container {
  margin: 3rem 0;
}
</style>
